<template>
	<div>
		<el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px">
			<el-form-item label="规格名称" prop="propName">
				<el-input v-model="form.propName" style="width: 40%;"></el-input>
			</el-form-item>
			<el-form-item label="规格属性" style="margin-bottom: 10px;"></el-form-item>
			<div class="item-list">
				<el-button class="item" size="small" type="info" plain v-for="(item,index) in form.prodPropValues" :key="index">
					<span>{{item.propValue}}</span>
					<span class="close" @click="delItem(item)">×</span>
				</el-button>
				<div class="add-item">
					<el-input size="small" v-model="addVal" placeholder="请输入属性值"></el-input>
					<el-button size="small" type="custom" plain @click="addItem">添加</el-button>
				</div>
			</div>
			<div class="btnArea">
				<el-button size="small" @click="handleChangeClose">取 消</el-button>
				<el-button size="small" type="custom" @click="handleChangeConfirm">确 定</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
	import {checkArrayItemVal} from "../../assets/js/utils.js"
export default {
	props: {
		opentype: {//类型判断0自定义 1为新建，2为编辑，3为查看
			type: Number,
			default: 0
		},
		selectdata: {
			type: Object,
			default: () => {}
		},
	},
	data() {
		return {
			form: {
				propName:"",
				prodPropValues:[]
			},
			addVal:"",
			rules: {
				propName: [
					{ required: true, message: '请输入规格名称', trigger: 'blur' },
				]
			}
		};
	},
	mounted() {
		if(Object.keys(this.selectdata).length>0 && this.opentype==2){
			({...this.form} = {...this.selectdata})
		}
	},
	methods: {
		addItem(){
			if(!this.addVal) return this.$message.warning('请填写属性值！'); 
			const val = this.addVal.trim()
			if(checkArrayItemVal(this.form.prodPropValues,val,"propValue")) return this.$message.warning('请勿重复添加！');
			this.form.prodPropValues.push({propValue:val})
			this.addVal = ""
		},
		delItem(item){
			this.form.prodPropValues.splice(this.form.prodPropValues.findIndex(v=>v.propValue===item.propValue),1)
		},
		handleChangeClose(){
			this.$emit("schange")
		},
		handleChangeConfirm() {
			this.$refs['ruleForm'].validate(async valid => {
				if (valid) {
					let url="/sys/prodProp/save"
					if(this.opentype==2)
						url="/sys/prodProp/update"
					if(this.form.prodPropValues.length<1) return this.$message.error('请添加规格属性值！');
					await this.$axios('POST',url,this.form,true);
					this.$message.success('添加成功！');
					this.$emit('fchange', false);
					this.form.propName=""
					this.form.prodPropValues=[]
				} else {
					this.$message.error('请完善表单信息后重试！');
				}
			});
		}
	}
};
</script>

<style lang="scss">
	.item-list{
		@include flex-style(row,wrap,flex-start,center);
		.item{
			position: relative;
			cursor: auto;
			margin-right: 10px;
			margin-bottom: 10px;
			.close{
				position: absolute;
				z-index: 99;
				top: -8px;
				right: -3px;
				color: #ff0000;
				font-size: 14px;
				cursor: pointer;
			}
		}
		.add-item{
			margin-bottom: 10px;
		}
		.el-input.el-input--small{
			width: 110px;
		}
	}
</style>
